<div class="main-wrapper">
    <header>
        <div class="navbar">
            <ul>
                <li (click)="back()"><button><i nz-icon nzType="arrow-left" nzTheme="outline"></i></button></li>
                <li routerLink="/anasayfa"><a>Anasayfa</a></li>
            </ul>

            <div class="close">
                <button class="close-btn" (click)="close()">
                    <i nz-icon nzType="close" nzTheme="outline"></i>
                </button>
            </div>
        </div>
    </header>

    <router-outlet></router-outlet>

    <footer>

    </footer>
</div>