
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from 'src/environments/environment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ErrorHandleService } from './error-Handle.service';

@Injectable()
export class StateMainService {
    private baseUrl = environment.apiURL ;

  private authToken:any;
  constructor(private http: HttpClient, private errorHandleService: ErrorHandleService,public notification: NzNotificationService) {
     this.authToken = sessionStorage.getItem('digiUserAuth');
  }

  Get(url:any) {
    return this.http.get(this.baseUrl + url,
      { headers: { 'Authorization':`Bearer ${this.authToken}`  }})
      .catch(this.errorHandleService.HandleError);
  }

  GetID(url:any, id:any) {
    return this.http.get(this.baseUrl + url + '/' + id,
      { headers: { 'Authorization':`Bearer ${this.authToken}`  }})
      .catch(this.errorHandleService.HandleError);
  }
 
  Create(url:any, obj: any, res?: any) {
    return this.http.post(this.baseUrl + url, obj,
      { headers: { 'Authorization':`Bearer ${this.authToken}`  }})
      .catch(this.errorHandleService.HandleError);
  }

  Update(url:any, obj:any) {
    return this.http.put(this.baseUrl + url, obj,
      { headers: { 'Authorization':`Bearer ${this.authToken}`  }})
      .catch(this.errorHandleService.HandleError);
  }

  Remove(url:any, id: number) {
    return this.http.delete(this.baseUrl + url + '/' + id,
    { headers: { 'Authorization': `Bearer ${this.authToken}`  }})
  }

  RemoveObj(url:any,obj:any) {
    return this.http.request('delete' , this.baseUrl + url ,
    { 
      body : obj,
      headers: { 'Authorization':`Bearer ${this.authToken}`  ,
    }})
  }


  formValidationMessage(invalidItems) {
    let fControls = invalidItems;
    for (const key in fControls) {
      if (Object.prototype.hasOwnProperty.call(fControls, key)) {
        const element = fControls[key];
        if (element.status == 'INVALID') {
          const messageKey = key.split('**')[0];
          if (messageKey === '*Stok adet') {
            this.notification.create('error', `${messageKey} hatalı`, null, { nzKey: `${messageKey}` });
            return;
          }
          this.notification.create('error', `${messageKey} hatalı girdiniz`, null, { nzKey: `${messageKey}` });
        }
      }
    }
  }

}
