import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from './pages/_default-layout/default-layout.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/giris' }, 
  {
    path: '', component: DefaultLayoutComponent,
    children: [
      { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
      { path: 'giris', loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule) },
      { path: 'kurumsal', loadChildren: () => import('./pages/corporate/corporate.module').then(m => m.CorporateModule) },
      { path: 'firmalar', loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule) },
      { path: 'teknik-bilgi', loadChildren: () => import('./pages/technical/technical.module').then(m => m.TechnicalModule) },
      { path: 'galeri', loadChildren: () => import('./pages/gallery/gallery.module').then(m => m.GalleryModule) },
      { path: 'iletisim', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
      { path: 'etap', loadChildren: () => import('./pages/stage/stage.module').then(m => m.StageModule) },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
