import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {

  constructor(private route:ActivatedRoute) {
   }
  
  back(){
    window.history.back();
  }

  close(){
    var win = window.open("","_self");
    win.close();
    }


  ngOnInit(): void {
  }

}
