// auth.guard.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { GlobalService } from '../global.service';
import { UserService } from '../user.service';


@Injectable()
export class OnlyCashier implements CanActivate {
  constructor(private user: UserService,private service:GlobalService, private router: Router,private notification: NzNotificationService) { }

  async canActivate() {
   // this.router.navigate(['/login']);
    // 1 token varmı kontrol et
    if (!this.user.isLoggedIn()) {
      this.router.navigate(['/login']);
      return false;
    }

  
   // alert('ONLY CASHIER AUTH')
    // tokenı apiden doğrula
     const checkToken = await this.verifyToken();
    if(checkToken){
        return true;
      }  
    return false;
  }

  verifyToken(){

    return new Promise((resolve)=>{
      this.service.Get('/Auth/LoginVerify').subscribe((resp:any)=>{
        if (resp.role == 'Magaza' || resp.role == 'Servis'  ) {
          /*  this.notification.create(
            'success',
            'Yetkiniz Onaylandı',
            ''
          );  */
          resolve(true)
          return resp;
        }
       // if (resp.role == 'Teknisyen' ) {
          /*  this.notification.create(
            'success',
            'Yetkiniz Onaylandı',
            ''
          );  */
         // this.router.navigate(['/urun-fiyat-liste/listele']);
      //    resolve(true)
      //    return resp;
     //   }
        this.notification.create(
          'error',
          'Sadece Satış Personelleri Giriş Yapabilir',
          ''
        );
        resolve(false)
      
      }, (error:any)=>{
      //  sessionStorage.removeItem('digiAuth');
      //  sessionStorage.removeItem('expiration');
      //  this.router.navigate(['/login']);
      this.notification.create(
        'error',
        'Sadece Yetkili Kullanıcılar Giriş Yapabilir ',
        ''
      );
      this.router.navigate(['/login']);
        // burası return false olacak
        resolve(false)
      })
   
    })
  }


}
