import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { GlobalService } from './@core/modules/services/global.service';
import { UserService } from './@core/modules/services/user.service';
import { WorkOrderService } from './@core/modules/services/work-order.service';
import { AuthGuard } from './@core/modules/services/_base_services/auth.guard';
import { OnlyAdmins } from './@core/modules/services/_base_services/auth.only-admins';
import { OnlyCashier } from './@core/modules/services/_base_services/auth.only-cashier';
import { ErrorHandleService } from './@core/modules/services/_base_services/error-Handle.service';
import { MainService } from './@core/modules/services/_base_services/main.service';
import { RoleGuardService } from './@core/modules/services/_base_services/role-guard.service';
import { StateMainService } from './@core/modules/services/_base_services/state-main.service';
import { UserRolesService } from './@core/modules/services/_base_services/user-roles.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './pages/_default-layout/default-layout.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { tr_TR } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import tr from '@angular/common/locales/tr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { GalleryConfig, GalleryModule } from 'ng-gallery';

export const config : GalleryConfig = {
  // ...
}
registerLocaleData(tr);

const _default_layout = [
  DefaultLayoutComponent
];


@NgModule({
  declarations: [
    AppComponent,
    ..._default_layout
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    OverlayModule,
    NzIconModule,
    BrowserAnimationsModule,
    GalleryModule.forRoot({
      style: {
        height: '840px',
        width: '1600px'
      },
    })
    
  ],
  providers: [
    MainService,
    StateMainService,
    GlobalService,
    UserService,
    ErrorHandleService,
    WorkOrderService,
    AuthGuard,
    OnlyAdmins,
    OnlyCashier,
    UserRolesService,
    RoleGuardService,
    { provide: NZ_I18N, useValue: tr_TR },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
